import React from "react";

export default function NotFoundScreen() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        margin: "80px",
      }}
    >
      <div>
        <p style={{ fontSize: "200px" }}>404</p>
      </div>

      <div>
        <p style={{ fontSize: "40px", textAlign: "center" }}>
          Oups! La page demandée n'existe pas... mais vous pouvez toujours venir
          manger au restaurant si vous avez faim.. ou boire un Cocktail au
          soleil s'il fait trop chaud. 😊
        </p>
      </div>
    </div>
  );
}
