import React, { useState, useEffect } from "react";
import { Icon, Grid, Message } from "semantic-ui-react";
import BandeauTel from "./BandeauTel";
import BtnReservation from "../components/BtnReservation";
import videoSalsa from "../images/soirée_salsa.mp4";
import firebase from "../firebase/firebase";
import GoogleAvis from "../components/GoogleAvis";
import posterVideo from "../assets/poster.webp";
import HoraireScreen from "./Horaires";

function HomeScreen() {
  const [article, setArticle] = useState();

  useEffect(() => {
    // const quotesDB = firebase.database().ref("le-bahia-default-rtdb");
    const quotesDB = firebase.database().ref("contentHome");
    quotesDB.on("value", (snapshot) => {
      setArticle(snapshot.val().content);
    });
  }, []);

  return (
    <div className="HomeScreen">
      <BandeauTel />
      <div className="container">
        <Grid stackable columns="2">
          <Grid.Column
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <img className="photoContent" alt="plat" src={picPortal} /> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <video
                poster={window.innerWidth < 500 ? posterVideo : false}
                src={videoSalsa}
                style={{
                  borderRadius: "10px",
                  marginTop: "20px",
                }}
                type="video/mp4"
                width="100%"
                controls
              />
            </div>
          </Grid.Column>

          <Grid.Column
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {<div dangerouslySetInnerHTML={{ __html: article }} />}
          </Grid.Column>
        </Grid>
      </div>
      <div className="container2">
        <Grid stackable columns="2">
          <Grid.Column>
            <HoraireScreen />
          </Grid.Column>

          <Grid.Column>
            <div className="reservation">
              <h1>
                <center>
                  <Icon name="mouse pointer" /> Réservation en ligne
                </center>
              </h1>
              <p>
                Les tables au plus près du lac sont attribuées par ordre de
                réservation.
              </p>{" "}
              <br />
              <BtnReservation />
              <br />
              <p>
                Si vous souhaitez une table à l'abri, merci de le préciser. Pour
                toutes autres demandes (bains de soleils, paddles, groupes,
                etc...), merci de nous appeler au 0476556353.
              </p>
              <Message>
                En cas de météo défavorable, notre capacité d'accueil sera remis
                en question.
              </Message>
            </div>
          </Grid.Column>
        </Grid>
        <GoogleAvis />
        {/* <div
          style={{ width: "100%", marginTop: "20px" }}
          class="elfsight-app-24b35c45-66bc-4e6e-88aa-71f5e938237a"
        ></div> */}
      </div>
    </div>
  );
}

export default HomeScreen;
