import React from "react";
import { Icon, Button } from "semantic-ui-react";

function BtnReservation() {
  const ouvrirNouvelOnglet = () => {
    const url = "https://app.overfull.fr/booking/?key_id=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJrZXkiOjIyOX0.yWTKw-cyPTwLYgTm2hsf09iEDBuvqIy9YE7TQwaeRR0&source=Web";
    window.open(url, '_blank');
  };

  return (
    <div>
      <Button
        onClick={ouvrirNouvelOnglet}
        animated
        color="teal"
        size="big"
      >
        <Button.Content visible>Réserver une table</Button.Content>
        <Button.Content hidden>
          <Icon name="arrow right" />
        </Button.Content>
      </Button>
    </div>
  );
}

export default BtnReservation;
