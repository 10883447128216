import React, { useEffect, useState } from "react";
import { Rating, Button, Icon } from "semantic-ui-react";
import axios from "axios";
import Carousel from "react-elastic-carousel";

function GoogleAvis() {
  const [dataAvisRating, setDataAvisRating] = useState(4);
  const [dataAvisTotal, setDataAvisTotal] = useState("Chargement des avis");
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {
    const addReviews = async () => {
      const result = await axios(
        "https://google-reviews.onrender.com/google-reviews?search=le+bahia+charavines"
      );
      setDataAvisTotal(result.data.result.number_rating);
      setDataAvisRating(result.data.result.rating);
      var tab = result.data.result.reviews;
      setDataArray(tab.filter((item) => item.rating >= 4));
    };
    addReviews();
  }, []);

  const listItems = dataArray.map((item) => (
    <div className="CardsAvis">
      <div className="ContainerIdAvis">
        <div>
          <img width="44" alt="profil_img" src={item.img}></img>
        </div>
        <div className="ContainerIdName">
          <div>{item.pseudo}</div>
          <div>
            <Rating
              disabled
              maxRating={5}
              defaultRating={item.rating}
              icon="star"
              size="huge"
            />
          </div>
          <div style={{ color: "#8FA68E" }}>{item.time}</div>
        </div>
      </div>
      <p className="CommentaireAvis">{item.comment}</p>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon name="google" size="big" />
        Posté sur Google
      </div>
    </div>
  ));

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
    { width: 1500, itemsToShow: 5 },
  ];

  return (
    <div className="Avis1">
      <div className="Avis2">
        <div className="AvisStars">
          <Rating
            style={{ marginLeft: "20px" }}
            disabled
            maxRating={5}
            defaultRating={dataAvisRating}
            icon="star"
            size="huge"
          />{" "}
          <p style={{ fontSize: "12px", marginLeft: "20px", color: "#8FA68E" }}>
            {dataAvisTotal} Google
          </p>
        </div>
        <a
          href="https://search.google.com/local/writereview?placeid=ChIJV6yGiGPgikcRJ3ygZr4Tt38"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            color="olive"
            // style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            Laisser un avis
          </Button>
        </a>
      </div>
      <div hidden={dataArray.length > 0 ? false : true}>
        <Carousel
          breakPoints={breakPoints}
          showArrows={window.innerWidth < 500 ? false : true}
        >
          {listItems}
        </Carousel>
      </div>
    </div>
  );
}

export default GoogleAvis;
