import React, { useState, useEffect } from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import firebase from "../firebase/firebase";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const restaurant =
  "https://firebasestorage.googleapis.com/v0/b/le-bahia.appspot.com/o/folder%2Fla-carte-du-restaurant-complete.pdf?alt=media&token=d797e0e8-19f1-4634-86f6-a3659458c12e";
  const boissons =
  "https://firebasestorage.googleapis.com/v0/b/le-bahia.appspot.com/o/folder%2Fboissons.pdf?alt=media&token=1033db64-6da9-407e-bc66-ecf5bf9388c8";
  const cocktailsGlaces =
  "https://firebasestorage.googleapis.com/v0/b/le-bahia.appspot.com/o/folder%2Fcocktails-glaces.pdf?alt=media&token=8282c0cc-03c6-4d8b-801d-a00e1e45b5e6";
  const vinsChampagnes =
  "https://firebasestorage.googleapis.com/v0/b/le-bahia.appspot.com/o/folder%2Fles-vins-champagnes.pdf?alt=media&token=83b449b9-e0bf-4cb1-81d9-5e0b9c41491d";

const cardSelectedStyle = { backgroundColor: "#499453" };

function CarteScreen() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [cardSelected, setCardSelected] = useState(restaurant);
  const [size, setSize] = useState(null);
  const [numberSwip, setNumberSwip] = useState(null);
  const width = useWindowWidth();

  useEffect(() => {
    firebase.analytics().logEvent("Affichage de la carte");
    if (width < 500) {
      setSize(width);
      setNumberSwip(1);
    } else {
      setSize(Math.min(width * 0.39, 1000));
      setNumberSwip(1); // SUR 1 car sur 2 bug affichage
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className="container">
        <center>
          <h1>Nos cartes</h1>
        </center>

        <div>
          <div className="selectedCarte">
            <Grid stackable columns="4">
              <Grid.Column>
                <div
                  className="cardCarte"
                  style={cardSelected === cocktailsGlaces ? cardSelectedStyle : null}
                  onClick={() => {
                    setCardSelected(cocktailsGlaces);
                    setPageNumber(1);
                    firebase.analytics().logEvent("CarteDuRestaurant");
                    document.location.href = "#pdfDocument";
                  }}
                >
                  <p>
                    <Icon name="glass martini" /> Cocktails et Glaces
                  </p>
                </div>
              </Grid.Column>

              <Grid.Column>
                <div
                  className="cardCarte"
                  style={cardSelected === vinsChampagnes ? cardSelectedStyle : null}
                  onClick={() => {
                    setCardSelected(vinsChampagnes);
                    setPageNumber(1);
                    firebase.analytics().logEvent("CarteTerrasse");
                    document.location.href = "#pdfDocument";
                  }}
                >
                  <p>
                    <Icon name="tint" /> Vins et Champagnes
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div
                  className="cardCarte"
                  style={cardSelected === restaurant ? cardSelectedStyle : null}
                  onClick={() => {
                    setCardSelected(restaurant);
                    setPageNumber(1);
                    firebase.analytics().logEvent("CarteVinsChampagnes");
                    document.location.href = "#pdfDocument";
                  }}
                >
                  <p>
                    <Icon name="food" /> Restaurant
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div
                  className="cardCarte"
                  style={cardSelected === boissons ? cardSelectedStyle : null}
                  onClick={() => {
                    setCardSelected(boissons);
                    setPageNumber(1);
                    firebase.analytics().logEvent("CarteDuRestaurant");
                    document.location.href = "#pdfDocument";
                  }}
                >
                  <p>
                    <Icon name="bar" /> Boissons et P'tite Faim
                  </p>
                </div>
              </Grid.Column>
              {/* <Grid.Column>
                <div
                  className="cardCarte"
                  style={cardSelected === vins ? cardSelectedStyle : null}
                  onClick={() => {
                    setCardSelected(vins);
                    setPageNumber(1);
                    firebase.analytics().logEvent("CarteVinsChampagnes");
                  }}
                >
                  <p>
                    <FontAwesomeIcon icon={faWineGlass} /> Vins et Champagnes
                  </p>
                </div>
              </Grid.Column> */}
            </Grid>
          </div>
          <div id="pdfDocument">
            <Document
              noData="Selectionnez une carte ci-dessus"
              loading="Chargement de la carte..."
              className="menu"
              file={cardSelected}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              Page {pageNumber} sur {numPages}
              <Button.Group className="btnSwipPageMobile">
                <Button
                  labelPosition="left"
                  icon="left chevron"
                  content="Précédent"
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - numberSwip);
                    }
                  }}
                />
                <Button
                  labelPosition="right"
                  icon="right chevron"
                  content="Suivant"
                  onClick={() => {
                    if (pageNumber < numPages) {
                      setPageNumber(pageNumber + numberSwip);
                    }
                  }}
                />{" "}
              </Button.Group>{" "}
              <div className="pages-menu">
                <Button
                  className="btnSwipPageDesktop"
                  labelPosition="left"
                  icon="left chevron"
                  content="Précédent"
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - numberSwip);
                    }
                  }}
                />

                <Page width={size} pageNumber={pageNumber} />
                <Page
                  className="pageCarteResponsive"
                  width={size}
                  error=""
                  pageNumber={pageNumber + 1}
                />

                <Button
                  className="btnSwipPageDesktop"
                  labelPosition="right"
                  icon="right chevron"
                  content="Suivant"
                  onClick={() => {
                    if (pageNumber < numPages) {
                      setPageNumber(pageNumber + numberSwip);
                    }
                    document.location.href = "#pdfDocument";
                  }}
                />
              </div>
              <Button.Group className="btnSwipPageMobile">
                <Button
                  labelPosition="left"
                  icon="left chevron"
                  content="Précédent"
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - numberSwip);
                    }
                  }}
                />
                <Button
                  labelPosition="right"
                  icon="right chevron"
                  content="Suivant"
                  onClick={() => {
                    if (pageNumber < numPages) {
                      setPageNumber(pageNumber + numberSwip);
                    }
                  }}
                />{" "}
              </Button.Group>{" "}
              Page {pageNumber} sur {numPages}
            </Document>
          </div>
        </div>
      </div>
      <center>
        <p style={{ margin: "20px" }}>
          L'abus d'alcool est dangereux pour la santé, consommer avec
          modération.
        </p>
      </center>
    </>
  );
}

export default CarteScreen;
