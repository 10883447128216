import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import firebase from "../firebase/firebase";
import { Popup } from "semantic-ui-react";

export default function EditorHome() {
  const [value, setValue] = useState("");

  useEffect(() => {
    const quotesDB = firebase.database().ref("contentHome");
    quotesDB.on("value", (snapshot) => {
      setValue(snapshot.val().content);
    });
  }, []);

  const sendText = async () => {
    let quote = firebase.database().ref("contentHome");
    quote.update({
      content: value,
    });
  };

  return (
    <div>
      <ReactQuill theme="snow" value={value} onChange={setValue} />
      <center>
        <Popup
          content="C'est réussi tu peux aller voir sur la page d'accueil 👍"
          on="click"
          pinned
          trigger={
            <button
              style={{ marginTop: "10px" }}
              onClick={() => {
                sendText();
              }}
            >
              Modifier texte
            </button>
          }
        />
      </center>
    </div>
  );
}
