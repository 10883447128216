import React, { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGuitar } from "@fortawesome/free-solid-svg-icons";
import { faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import { faWater } from "@fortawesome/free-solid-svg-icons";
import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons";
import firebase from "../firebase/firebase";
import videoSalsa from "../images/soirée_salsa.mp4";
import imgPaddle from "../images/paddle.jpg";
import imgSolarium from "../images/solarium.jpg";
import imgEvenement from "../images/evenement.jpg";

import BandeauTel from "./BandeauTel";

export default function ServicesScreen() {
  useEffect(() => {
    firebase.analytics().logEvent("affichage_nosServices");
  }, []);

  const style = {
    img: {
      borderRadius: "10px",
    },
    title: {
      fontSize: "35px",
    },
    card: {
      textAlign: "center",
      boxShadow: "0px 0px 9px -2px #000000",
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "#405e4538",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  };
  return (
    <>
      <h1
        data-aos="flip-down"
        data-aos-offset="0"
        style={{ textAlign: "center", margin: "30px" }}
      >
        Découvrir les activités que nous vous proposons
      </h1>
      <div className="container">
        <Grid stackable columns="2">
          <Grid.Column>
            <div data-aos="fade-right" style={style.card}>
              <h1 style={style.title}>
                <FontAwesomeIcon icon={faGuitar} /> Soirées Salsa
              </h1>
              <p>
                Nous vous faisons danser tous les jeudis soirs de juillet et
                aout en extérieur depuis plus de 10 ans. <br />
                Soirée annulée en cas de mauvais temps ou de pandémie mondiale.
              </p>
              <video
                src={videoSalsa}
                type="video/mp4"
                width="100%"
                style={style.img}
                controls
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div data-aos="fade-left" style={style.card}>
              <h1 style={style.title}>
                <FontAwesomeIcon icon={faUmbrellaBeach} /> Solarium
              </h1>
              <p>
                Profitez, en supplément de votre repas, de l'accès à notre
                solarium de 11H30 à 18H30 (Bains de soleil, Accès au lac, Bar,
                Pétanque, etc...) pour 10€ en semaine et 15€ le week-end.
              </p>
              <img
                src={imgSolarium}
                style={style.img}
                width="100%"
                alt="paddle"
              />
            </div>
          </Grid.Column>
        </Grid>

        <Grid stackable columns="2">
          <Grid.Column>
            <div data-aos="fade-right" style={style.card}>
              <h1 style={style.title}>
                <FontAwesomeIcon icon={faWater} /> Stand-Up Paddles
              </h1>
              <p>
                Nous disposons de de plusieurs paddle en location (15€/Heure).
                Tarif dégréssif selon la durée ou le nombre de paddle loués.
                <br />
                Port du gilet de sauvetage obligatoire. (Inclus dans la
                location)
              </p>
              <img
                src={imgPaddle}
                style={style.img}
                width="100%"
                alt="paddle"
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div data-aos="fade-left" style={style.card}>
              <h1 style={style.title}>
                <FontAwesomeIcon icon={faBirthdayCake} /> Privatisation
              </h1>
              <p>
                N'hésitez pas à nous consulter, nous pouvons privatiser des
                espaces tel que notre solarium pour un événement privé
                (Anniversaire, Entreprise, etc...).
              </p>
              <img
                src={imgEvenement}
                style={style.img}
                width="100%"
                alt="paddle"
              />
            </div>
          </Grid.Column>
        </Grid>
      </div>
      <div style={{ marginTop: "20px" }}>
        <h1 style={{ textAlign: "center" }}>Coordonnées de réservation</h1>
        <BandeauTel />
      </div>
    </>
  );
}
