import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import BandeauTel from "./BandeauTel";
import BtnReservation from "../components/BtnReservation";
import firebase from "../firebase/firebase";

function ContactScreen() {
  useEffect(() => {
    firebase.analytics().logEvent("Affichage_contact");
  }, []);
  return (
    <>
      <BandeauTel />

      <div>
        <iframe
          title="Map"
          className="iframeMap"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=5.513851374853403%2C45.43248055493062%2C5.518357485998423%2C45.43536407032887&amp;layer=mapnik&amp;marker=45.43392233104639%2C5.516104430425912"
        ></iframe>
        <br />
      </div>
      <div className="coordonnées">
        <p>
          <Icon name="mail" /> contact@le-bahia.fr
        </p>
        <BtnReservation />
      </div>
    </>
  );
}

export default ContactScreen;
