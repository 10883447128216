import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyDtoMhDqKhYQLT3RohicX0Dwsyf55S_ZKo",
  authDomain: "le-bahia.firebaseapp.com",
  databaseURL: "https://le-bahia-default-rtdb.firebaseio.com",
  projectId: "le-bahia",
  storageBucket: "le-bahia.appspot.com",
  messagingSenderId: "108842063580",
  appId: "1:108842063580:web:10710595c11069a3e94619",
  measurementId: "G-RCWEB2HYMQ",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export default firebase;
