import React from "react";

function FooterScreen() {
  return (
    <footer className="footer">
      <span>
        Le Bahia 2023
        {/* <br />
        <a className="linkArm" href="#" rel="noreferrer">
          Armand G.
        </a> */}
      </span>
    </footer>
  );
}

export default FooterScreen;
