import React, { Component } from "react";
import firebase from "../firebase/firebase";
import "../upload.css";
import { Grid } from "semantic-ui-react";
import EditHoraires from "../components/EditHoraires";
import EditorHome from "../components/EditorHome";
import HorairesScreen from "./Horaires";

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      image: null,
      namePDF: null,
      progress: 0,
      downloadURL: null,
    };
  }

  handleChange = (e) => {
    if (e.target.files[0]) {
      this.setState({
        image: e.target.files[0],
        namePDF: e.target.files[0].name,
      });
    }
  };

  handleUpload = (name) => {
    if (this.state.namePDF != null) {
      let file = this.state.image;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var uploadTask = storageRef.child("folder/" + name).put(file);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          var progress =
            Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({ progress });
        },
        (error) => {
          throw error;
        },
        () => {
          // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{

          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            this.setState({
              downloadURL: url,
            });
          });
          document.getElementById("file").value = null;
        }
      );
    }
  };

  render() {
    return (
      <>
        <div
          style={{
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 style={{ marginTop: "30px" }}>
            Modification du texte de la page d'accueil
          </h1>
          <EditorHome />
        </div>
        <div className="container">
          <center>
            <h1>Administration des cartes du restaurant</h1>
            {this.state.namePDF}
            <label>
              Choisir un fichier PDF
              <input type="file" id="file" onChange={this.handleChange} />
            </label>
            Progression : {this.state.progress}/100
            <br />
            <br />
          </center>
          <div className="adminCartes">
            <div className="reservation">
              <p>Carte du restaurant</p>
              <button
                className="buttonUpload"
                onClick={() => {
                  this.handleUpload("la-carte-du-restaurant-complete.pdf");
                }}
              >
                Envoyer
              </button>
            </div>
            <div className="reservation">
              <p>Cocktails et Glaces</p>
              <button
                className="buttonUpload"
                onClick={() => {
                  this.handleUpload("cocktails-glaces.pdf");
                }}
              >
                Envoyer
              </button>
            </div>
            <div className="reservation">
              <p>Vins et Champagnes</p>
              <button
                className="buttonUpload"
                onClick={() => {
                  this.handleUpload("les-vins-champagnes.pdf");
                }}
              >
                Envoyer
              </button>
            </div>
            <div className="reservation">
              <p>Boissons</p>
              <button
                className="buttonUpload"
                onClick={() => {
                  this.handleUpload("boissons.pdf");
                }}
              >
                Envoyer
              </button>
            </div>
            {/* <div className="reservation">
              <p>Les vins et champagnes</p>
              <button
                className="buttonUpload"
                onClick={() => {
                  this.handleUpload("les-vins-champagnes.pdf");
                }}
              >
                Envoyer
              </button>
            </div> */}
          </div>
        </div>

        <div className="container2">
          <Grid stackable columns="2">
            <Grid.Column>
              <div className="horaires">
                <p>Changer les horaires d'ouverture :</p>
                <EditHoraires />
              </div>
            </Grid.Column>
            <Grid.Column>
              <HorairesScreen />
            </Grid.Column>
          </Grid>
          <Grid stackable columns="1">
            <Grid.Column>
              <p>Envoyer des images et vidéos directement via Cloudinary :</p>
              <ol>
                <li>
                  Se connecter sur{" "}
                  <a
                    href="https://cloudinary.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://cloudinary.com/
                  </a>{" "}
                </li>
                <li>Envoyer les images/vidéos dans le dossier Le Bahia</li>
                <li>
                  ATTENTION : Selectionnes toutes les images et ajoutes le tag
                  "bahia" à toutes les images du dossier sinon elles ne
                  s'afficheront pas.
                </li>
                <li>
                  Attendre 3 minutes environs et les images seront visibles sur
                  la page Photo :)
                </li>
              </ol>
            </Grid.Column>
          </Grid>
        </div>
      </>
    );
  }
}
