import React from "react";
import { Button, Icon } from "semantic-ui-react";

function BandeauTel() {
  return (
    <div data-aos="flip-down" data-aos-offset="0" className="contact">
      <p>
        <Icon name="location arrow" size="small" /> 50 Chemin des Plages, 38850
        Charavines{" "}
      </p>
      <div className="btnReseaux">
        <a
          href="https://www.instagram.com/resto.lebahia/"
          target="_blank"
          rel="noreferrer"
          style={{ margin: "10px" }}
        >
          <Button color="instagram">
            <Icon name="instagram" /> Instagram
          </Button>
        </a>

        <a
          href="https://m.facebook.com/pg/RestaurantLeBahia/photos/?ref=page_internal&mt_nav=0"
          rel="noreferrer"
          target="_blank"
          style={{ margin: "10px" }}
        >
          <Button color="facebook">
            <Icon name="facebook" /> Facebook
          </Button>
        </a>
      </div>
      <p className="tel">
        <a href="tel:0476556353">
          <Icon name="phone" size="small" /> 04 76 55 63 53
        </a>
      </p>
    </div>
  );
}

export default BandeauTel;
