import React, { useEffect, useState } from "react";
import { ProGallery } from "pro-gallery";
import "pro-gallery/dist/statics/main.css";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import axios from "axios";
import firebase from "../firebase/firebase";

function PhotosScreen() {
  const [items, setItems] = useState([]);

  const width = useWindowWidth();

  useEffect(() => {
    firebase.analytics().logEvent("affichage_photos");
    setItems([]);
    const recupererImages = async () => {
      const result = await axios(
        "https://res.cloudinary.com/sarl-le-bahia-ltmb/image/list/bahia.json"
      );
      for (let i = 0; i < result.data.resources.length; i++) {
        const conversion = {
          itemId: result.data.resources[i].public_id,
          mediaUrl: `https://res.cloudinary.com/sarl-le-bahia-ltmb/image/upload/q_50/v1625931130/${result.data.resources[i].public_id}.${result.data.resources[i].format}`,
          metaData: {
            type: "image",
            height: 200, //Math.min(width * 0.39, result.data.resources[i].height),
            width: 100, //Math.min(width * 0.39, result.data.resources[i].width),
            title: result.data.resources[i].public_id,
            description: "sample-description",
            focalPoint: [0, 0],
            link: {
              url: `https://res.cloudinary.com/sarl-le-bahia-ltmb/image/upload/v1625931130/${result.data.resources[i].public_id}.${result.data.resources[i].format}`,
              target: "_blank",
            },
          },
        };
        setItems((items) => [...items, conversion]);
      }
    };
    recupererImages();
  }, []);

  // The options of the gallery (from the playground current state)
  const options = {
    galleryLayout: 4, // galleryLayout: -1, pour un affichage différent
    hoveringBehaviour: "NEVER_SHOW",
    imageHoverAnimation: "ZOOM_IN",
    scrollAnimation: "FADE_IN",
    gridStyle: width < 500 ? 1 : 0,
    itemBorderRadius: 10,
    arrowsSize: width < 500 ? 10 : 70,
    arrowsPosition: 0,
  };

  // The size of the gallery container. The images will fit themselves in it
  const container = {
    width: Math.min(width * 1, 1300),
    height: window.innerHeight,
  };

  // // The eventsListener will notify you anytime something has happened in the gallery.
  // const eventsListener = (eventName, eventData) =>
  //   console.log({ eventName, eventData });

  // The scrollingElement is usually the window, if you are scrolling inside another element, suplly it here
  const scrollingElement = window;

  return (
    <>
      <div
        className="gallery"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{ textAlign: "center", marginBottom: "40px" }}>
          Découvrir les plus belles photos du restaurant
        </h1>
        <div>
          <ProGallery
            items={items}
            options={options}
            container={container}
            scrollingElement={scrollingElement}
          />
        </div>
      </div>
    </>
  );
}

export default PhotosScreen;
