import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import firebase from "../firebase/firebase";

function EditHoraires() {
  const [horairesData, setHorairesData] = useState({
    lundi: "",
    mardi: "",
    mercredi: "",
    jeudi: "",
    vendredi: "",
    samedi: "",
    dimanche: "",
    portail: "",
    infos: "",
  });
  const [newHoraires, setNewHoraires] = useState();

  useEffect(() => {
    const quotesDB = firebase.database().ref("horaires");
    quotesDB.on("value", (snapshot) => {
      setHorairesData(snapshot.val());
    });
  }, []);

  const sendText = async () => {
    let quote = firebase.database().ref("horaires");
    quote.update(newHoraires);
  };

  const changeHoraires = (value, name) => {
    value < 1
      ? (newHoraires[name] = horairesData[name])
      : setNewHoraires({ ...newHoraires, [name]: value });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        fontSize: "15px",
      }}
    >
      <Input
        name="infos"
        style={{ width: "100%" }}
        label="Message d'infos"
        placeholder={horairesData.infos}
        onChange={(e) => {
          changeHoraires(e.target.value, e.target.name);
        }}
      />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "300px",
        }}
      >
        <Input
          name="lundi"
          label="lundi"
          placeholder={horairesData.lundi}
          onChange={(e) => {
            changeHoraires(e.target.value, e.target.name);
          }}
        />
        <Input
          name="mardi"
          label="mardi"
          placeholder={horairesData.mardi}
          onChange={(e) => {
            changeHoraires(e.target.value, e.target.name);
          }}
        />
        <Input
          name="mercredi"
          label="mercredi"
          placeholder={horairesData.mercredi}
          onChange={(e) => {
            changeHoraires(e.target.value, e.target.name);
          }}
        />
        <Input
          name="jeudi"
          label="jeudi"
          placeholder={horairesData.jeudi}
          onChange={(e) => {
            changeHoraires(e.target.value, e.target.name);
          }}
        />
        <Input
          name="vendredi"
          label="vendredi"
          placeholder={horairesData.vendredi}
          onChange={(e) => {
            changeHoraires(e.target.value, e.target.name);
          }}
        />
        <Input
          name="samedi"
          label="samedi"
          placeholder={horairesData.samedi}
          onChange={(e) => {
            changeHoraires(e.target.value, e.target.name);
          }}
        />
        <Input
          name="dimanche"
          label="dimanche"
          placeholder={horairesData.dimanche}
          onChange={(e) => {
            changeHoraires(e.target.value, e.target.name);
          }}
        />
        <br />
        <Input
          name="portail"
          label="Bannière"
          placeholder={horairesData.portail}
          onChange={(e) => {
            changeHoraires(e.target.value, e.target.name);
          }}
        />
        <br />

        <Button onClick={() => sendText()}>Modifier</Button>
        <p style={{ fontSize: "13px", color: "cyan" }}>⚠ Aperçu à droite.</p>
      </div>
    </div>
  );
}

export default EditHoraires;
