import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import PanelScreen from "./PanelScreen";

function AdminScreen() {
  const [log, setLog] = useState(null);
  const [identifiant, setIdentifiant] = useState("");
  const [password, setPassword] = useState("");

  const signIn = () => {
    if (identifiant === "bahia" && password === "bahia01234") {
      setLog(<PanelScreen />);
      let d1 = document.getElementsByClassName("signIn");
      d1[0].style.display = "none";
    } else {
      setLog(<center> Mot de passe incorrect !</center>);
    }
  };

  return (
    <>
      <div className="signIn">
        <Form>
          <Form.Field>
            Identifiant
            <input
              placeholder="Identifiant"
              onChange={(e) => setIdentifiant(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            Mot de passe
            <input
              type="password"
              placeholder="Mot de passe"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Field>
          <Button type="submit" onClick={() => signIn()}>
            Entrer
          </Button>
        </Form>
      </div>
      {log}
    </>
  );
}

export default AdminScreen;
