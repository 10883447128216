import logo from "../images/logo2.webp";
import { Link } from "react-router-dom";

import ResponsiveMenu from "./ResponsiveMenu";

import "../Home.css";

function HeaderScreen() {
  return (
    <div className="HeaderScreen">
      <ResponsiveMenu />
      <header className="header">
        <div className="nav">
          <div>
            <Link to="/">Accueil</Link>
          </div>
          <div>
            <Link to="/restaurant">Nos services</Link>
          </div>
          <div>
            <Link to="/carte">La carte</Link>
          </div>

          <div>
            <Link to="/photos">Photos</Link>
          </div>
          <div>
            <Link to="/contact">Nous contacter</Link>
          </div>
        </div>
        <div className="banniere">
          {" "}
          <img
            data-aos="zoom-in"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="800"
            className="logo"
            alt="logo le bahia"
            src={logo}
          />
        </div>
      </header>
    </div>
  );
}

export default HeaderScreen;
