import React, { useState } from "react";
import { ReactComponent as CloseMenu } from "../assets/x-lg.svg";
import { ReactComponent as MenuIcon } from "../assets/hamburger.svg";
import { Link } from "react-router-dom";

import "../ResponsiveMenu.css";

function ResponsiveMenu() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <div className="ResponsiveMenu">
      <div className="logo-nav">
        <div className="logo-container">Restaurant Le Bahia</div>

        <ul className={click ? "nav-options active" : "nav-options"}>
          <li className="option" onClick={closeMobileMenu}>
            <Link to="/">Accueil</Link>
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <Link to="/restaurant">Nos services</Link>
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <Link to="/carte">LA CARTE</Link>
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <Link to="/photos">PHOTOS</Link>
          </li>
          <li className="option mobile-option" onClick={closeMobileMenu}>
            <Link to="/contact">NOUS CONTACTER</Link>
          </li>
          <li className=" option mobile-option" onClick={closeMobileMenu}>
            <a
              href="https://app.overfull.fr/booking/?key_id=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJrZXkiOjIyOX0.yWTKw-cyPTwLYgTm2hsf09iEDBuvqIy9YE7TQwaeRR0&source=Web"
              target="_blank"
              rel="noreferrer"
              className="sign-up"
            >
              RÉSERVATION EN LIGNE
            </a>
          </li>
        </ul>
      </div>
      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <CloseMenu className="menu-icon" />
        ) : (
          <MenuIcon className="menu-icon" />
        )}
      </div>
    </div>
  );
}

export default ResponsiveMenu;
