import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HeaderScreen from "./views/HeaderScreen";
import Home from "./views/HomeScreen";
import FooterScreen from "./views/FooterScreen";
import MenusScreen from "./views/CarteScreen";
import ContactScreen from "./views/ContactScreen";
import AdminScreen from "./views/AdminScreen";
import PhotosScreen from "./views/PhotosScreen";
import NotFoundScreen from "./views/NotFoundScreen";
import ServicesScreen from "./views/ServicesScreen";

// Pour les animations
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
AOS.init();

function App() {
  return (
    <>
      <Router>
        <HeaderScreen />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/carte" component={MenusScreen} />
          <Route exact path="/contact" component={ContactScreen} />
          <Route exact path="/photos" component={PhotosScreen} />
          <Route exact path="/restaurant" component={ServicesScreen} />
          <Route exact path="/admin" component={AdminScreen} />
          <Route component={NotFoundScreen} />
        </Switch>
        <FooterScreen />
      </Router>
    </>
  );
}

export default App;
