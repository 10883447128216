import React, { useEffect, useState } from "react";
import firebase from "../firebase/firebase";
import { Icon } from "semantic-ui-react";

function Horaires() {
  const [horairesData, setHorairesData] = useState({
    lundi: "",
    mardi: "",
    mercredi: "",
    jeudi: "",
    vendredi: "",
    samedi: "",
    dimanche: "",
    portail: "",
  });

  useEffect(() => {
    const horairesDB = firebase.database().ref("horaires");
    horairesDB.on("value", (snapshot) => {
      let data = snapshot.val();
      setHorairesData({
        lundi: data.lundi,
        mardi: data.mardi,
        mercredi: data.mercredi,
        jeudi: data.jeudi,
        vendredi: data.vendredi,
        samedi: data.samedi,
        dimanche: data.dimanche,
        portail: data.portail,
        infos: data.infos,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="horaires">
      <h1>
        <center>
          <Icon name="clock" /> Horaires d'ouverture
        </center>
      </h1>
      <center>{horairesData.infos}</center>
      <div className="hoursWrapper">
        <div className="hoursBox">
          <div className="hoursContent">
            <div className="hoursTitle"></div>
            <div className="hours">
              <ul>
                <li>Lundi</li>
                <li>Mardi</li>
                <li>Mercredi</li>
                <li>Jeudi</li>
                <li>Vendredi</li>
                <li>Samedi</li>
                <li>Dimanche</li>
              </ul>
              <ul>
                <center>
                  <li>{horairesData.lundi}</li>
                  <li>{horairesData.mardi}</li>
                  <li>{horairesData.mercredi}</li>
                  <li>{horairesData.jeudi}</li>
                  <li>{horairesData.vendredi}</li>
                  <li>{horairesData.samedi}</li>
                  <li>{horairesData.dimanche}</li>
                </center>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <center>
        <span
          style={{
            borderRadius: "10px",
            backgroundColor: "#092B22",
            padding: "10px",
            border: "solid #DA2828 1px",
            color: "#DA2828",
          }}
        >
          {horairesData.portail}
        </span>
      </center>
    </div>
  );
}

export default Horaires;
